<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/threatcommunity.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/threatcommunities">Threat Communities</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active>
        Add Threat Community
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Threat Community
      </breadcrumb-item>
    </breadcrumb>
    <form autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <card v-if="loaded">
            <div>
              <div slot="header">
                <h4 class="card-title">Threat Profile</h4>
              </div>
              <fg-input
                name="name"
                label="Name"
                v-validate="modelValidations.name"
                :error="getError('name')"
                v-model="model.name"
              ></fg-input>
              <fg-input label="Motive">
                <textarea
                  name="motive"
                  v-model="model.motive"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Primary Intent">
                <textarea
                  name="primary intent"
                  v-model="model.primaryIntent"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Sponsorship">
                <textarea
                  name="sponsorship"
                  v-model="model.sponsorship"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Preferred Target">
                <textarea
                  name="preferred target"
                  v-model="model.preferredTarget"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Capability">
                <textarea
                  name="capability"
                  v-model="model.capability"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Risk Tolerance">
                <textarea
                  name="risk tolerance"
                  v-model="model.riskTolerance"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Concern for Damage">
                <textarea
                  name="concern for damage"
                  v-model="model.concernForDamage"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
            </div>
          </card>
        </div>

        <div class="col-md-6">
          <card v-if="loaded">
            <div>
              <fg-input
                name="user"
                disabled="true"
                label="modified by"
                v-model="model.user"
              ></fg-input>
              <fg-input name="lastModifiedDate" label="Last Modified Date">
                <el-date-picker
                  v-model="model.lastModifiedDate"
                  type="date"
                  disabled
                ></el-date-picker>
              </fg-input>
              <!--<span>{{ model.lastModifiedDate | moment("dddd, MMMM Do YYYY") }}</span>-->
            </div>
          </card>

          <card v-if="loaded">
            <div>
              <div slot="header">
                <h4 class="card-title">Threat Capability</h4>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <fg-input
                    label="minimum"
                    :error="getError('threat capability minimum')"
                  >
                    <el-input-number
                      v-model="model.tCapMin"
                      :max="100"
                      :min="1"
                      v-validate="{
                        required: true,
                        max_value: model.tCapML,
                        decimal: true,
                        between: [1, 100],
                      }"
                      name="threat capability minimum"
                    ></el-input-number>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input
                    label="most likely"
                    :error="getError('threat capability most likely')"
                  >
                    <el-input-number
                      v-model="model.tCapML"
                      :max="100"
                      :min="1"
                      v-validate="{
                        required: true,
                        max_value: model.tCapMax,
                        min_value: model.tCapMin,
                        decimal: true,
                        between: [1, 100],
                      }"
                      name="threat capability most likely"
                    ></el-input-number>
                  </fg-input>
                </div>
                <div class="col-md-4">
                  <fg-input
                    label="maximum"
                    :error="getError('threat capability maximum')"
                  >
                    <el-input-number
                      v-model="model.tCapMax"
                      :max="100"
                      :min="1"
                      v-validate="{
                        required: true,
                        min_value: model.tCapML,
                        decimal: true,
                        between: [1, 100],
                      }"
                      name="threat capability maximum"
                    ></el-input-number>
                  </fg-input>
                </div>
              </div>
              <fg-input
                label="Confidence Interval"
                :error="getError('confidence interval')"
              >
                <el-select
                  name="confidence interval"
                  v-validate="modelValidations.tCapConf"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlTCapConfChange"
                  v-model="model.tCapConf"
                  placeholder="Select Confidence Interval"
                >
                  <el-option
                    class="select-default"
                    v-for="item in model.tCapConfs"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </fg-input>
              <fg-input label="Rationale">
                <textarea
                  name="rationale"
                  v-model="model.tCapRationale"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Additional Information">
                <textarea
                  name="additionalInformation"
                  v-model="model.additionalInformation"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <checkbox v-model="checkboxValue">Active</checkbox>
            </div>
          </card>
          <div class="text-right">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-danger btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Cancel
              </button>
              <button
                type="submit"
                @click.prevent="validate"
                @click="save"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
            </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Select, Option, DatePicker } from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    Checkbox,
    [Option.name]: Option,
    [Select.name]: Select,
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        name: "",
        motive: "",
        primaryIntent: "",
        sponsorship: "",
        preferredTarget: "",
        capability: "",
        riskTolerance: "",
        concernForDamage: "",
        additionalInformation: "",
        tCapMin: 0,
        tCapMax: 0,
        tCapML: 0,
        tCapRationale: "",
        isActive: true,
        tCapConf: "",
        lastModifiedDate: new Date(),
        userId: 1,
        user: "",
        tCapConfs: ["Very Low", "Low", "Medium", "High", "Very High"],
      },

      modelValidations: {
        name: {
          required: true,
        },
        motive: {
          required: true,
        },
        primaryIntent: {
          required: true,
        },
        sponsorship: {
          required: true,
        },
        preferredTarget: {
          required: true,
        },
        capability: {
          required: true,
        },
        riskTolerance: {
          required: true,
        },
        concernForDamage: {
          required: true,
        },
        tCapRationale: {
          required: true,
        },
        tCapConf: {
          required: true,
        },
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.threatCommunity, isValid);
      });
    },
    get(id) {
      let self = this;
      if (id == undefined) {
        self.add = true;
        self.loaded = true;
        return;
      }
      self.$store.state.services.threatCommunityService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.motive = r.data.motive;
          self.model.primaryIntent = r.data.primaryIntent;
          self.model.sponsorship = r.data.sponsorship;
          self.model.preferredTarget = r.data.preferredTarget;
          self.model.capability = r.data.capability;
          self.model.riskTolerance = r.data.riskTolerance;
          self.model.concernForDamage = r.data.concernForDamage;
          self.model.additionalInformation = r.data.additionalInformation;
          self.model.tCapMin = r.data.tCapMin;
          self.model.tCapMax = r.data.tCapMax;
          self.model.tCapML = r.data.tCapML;
          self.model.tCapRationale = r.data.tCapRationale;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.model.tCapConf = r.data.tCapConf;
          self.model.lastModifiedDate = r.data.lastModifiedDate;
          self.model.userId = r.data.userId;
          self.model.user = r.data.user;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.model.tCapConf = self.model.tCapConf.replace(/\s/g, '');
            self.$store.state.services.threatCommunityService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          } else {
            self.model.isActive = self.checkboxValue.toString();
            self.model.tCapConf = self.model.tCapConf.replace(/\s/g, '');
            self.$store.state.services.threatCommunityService
              .add(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close(){
      let self = this;
      self.$router.push("/threatcommunities");
    },
    ddlTCapConfChange(value) {
      let self = this;
      self.model.tCapConf = value;
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Threat Community saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>
